import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Registro',
          path: '/registro_masivo',
          component: () => import('@/views/dashboard/registroInformacion'),
        },
        {
          name: 'Registro',
          path: '/registro',
          component: () => import('@/views/dashboard/listaProduccion'),
        },
        {
          name: 'Administración de juzgados',
          path: '/juzgados',
          component: () => import('@/views/dashboard/listaJuzgados'),
        },
        {
          name: 'Registro de usuarios',
          path: '/usuarios',
          component: () => import('@/views/dashboard/registroUsuarios'),
        },
        {
          name: 'Cambio de clave',
          path: '/clave',
          component: () => import('@/views/dashboard/cambioClave'),
        },
        {
          name: 'Cerrar Sesión',
          path: '/cerrar',
          component: () => import('@/views/dashboard/cerrarSesion'),
        },
        {
          name: 'Login',
          path: '/login',
          component: () => import('@/views/dashboard/loginUsuario'),
        },
        {
          name: 'Observatorio',
          path: '/obs_m',
          component: () => import('@/views/dashboard/observatorioDelitosM'),
        },
        {
          name: 'Observatorio',
          path: '*',
          component: () => import('@/views/dashboard/observatorioDelitos'),
        },
      ],
    },
  ],
})
