<template>
  <router-view />
</template>

<script>
  export default {
    name: 'Odajup',
    created () {
      localStorage.setItem('urlApiOdajup', 'https://odajuprest.csjla.pe/')
      // localStorage.setItem('urlApiOdajup', 'http://172.17.128.108:3010/')
      // localStorage.setItem('urlApiOdajup', 'http://172.17.128.108:3069/')
      localStorage.setItem('imgLogo', 'assets/logo_la.png')
      localStorage.setItem('imgLogoN', 'assets/logo_la_n.png')
      localStorage.setItem('imgFondo', 'assets/fondo_museo.jpeg')
    },
  }
</script>
