import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import VueMask from 'v-mask'
import '@/sass/overrides.sass'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)
Vue.use(VueMask)

const theme = {
  secondary: '#28aae2',
  // primary: '#991d1f',
  primary: '#991d1f',
  accent: '#9C27b0',
  info: '#00CAE3',
  gris: '#757575',
  color1: '#334F64',
  color2: '#eaebed',
  color3: '#ffd782',
  color4: '#eaebed',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: {
        primary: colors.red, // #E53935
        secondary: colors.red, // #FFCDD2
        accent: colors.indigo, // #3F51B5
      },
    },
  },
})
